<template>
    <div class="map-page map-page-list map-page-centers-list" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <div class="map-admin-list">
                <header class="map-admin-list-header">
                    <h1>List of centers</h1>
                </header>
                <div class="map-table map-centers-table" :class="{ 'map-loading': loading }">
                    <header>
                        <div class="map-table-col">#</div>
                        <div class="map-table-col">Title</div>
                        <div class="map-table-col">Spotlight Post</div>
                        <div class="map-table-col">Spotlight Post type</div>
                        <div class="map-table-col" v-if="logged_user.role === 'super-admin'">Actions</div>
                    </header>
                    <div class="map-table-body">
                        <loader />
                        <p class="map-table-empty" v-if="!has_centers && !loading">There are no items to display.</p>
                        <div class="map-table-row" v-for="(center, key) in centers" :key="key">
                            <div class="map-table-col">{{ key + 1 }}</div>
                            <div class="map-table-col">{{ center.name }}</div>
                            <div class="map-table-col">{{ center.spotlight.title || 'empty' }}</div>
                            <div class="map-table-col">{{ center.spotlight_type.replace('App\\Models\\', '') || 'empty' }}</div>
                            <div class="map-table-col map-no-bg" v-if="logged_user.role === 'super-admin'">
                                <a :href="`/admin/centers/${center.id}/edit`" class="map-button map-color-primary map-button-edit"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '@/components/Loader';

    import { CenterService } from '@/services';

    export default
    {
        name: 'PostsList',
        components:
        {
            Loader,
        },
        data()
        {
            return {
                loading: false,
                has_centers: false,
                centers: [],
            };
        },
        computed:
        {
            logged_user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            await this.get_centers();
        },
        methods:
        {
            async get_centers()
            {
                this.loading = true;

                this.centers = (await CenterService.all()).data;

                this.has_centers = this.centers && this.centers.length;

                this.loading = false;
            },
        },
    }
</script>
